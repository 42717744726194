import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import {
  HealthMeasurementIdentifier,
  useGlobalStore,
} from "../../../../../../store";
import { healthMeasurementListItemStyle } from "../../../../../../theme";
import FaceIconBrand from "../../../../../../theme/icons/FaceIconBrand";
import { calculateBMI, isOlderThanSixMonths } from "../../../../../../utils";
import HealthItemRiskIndicator from "../HealthRiskIndicator";
import IconLoader from "../../../../../Common/components/IconLoader";

const BodyMassIndexValue = ({
  healthProfileData,
}: {
  healthProfileData: any;
}) => {
  const { t } = useTranslation();

  if (
    healthProfileData["BodyMassIndex"] &&
    healthProfileData["BodyMassIndex"]?.valueAsJSON?.value
  ) {
    return (
      <Stack direction="row" alignItems="center">
        <Typography variant="h4">
          {healthProfileData["BodyMassIndex"].valueAsJSON.value}
        </Typography>
        <HealthItemRiskIndicator
          healthRiskIndicator={
            healthProfileData["BodyMassIndex"]?.healthRiskIndicator
          }
        />
      </Stack>
    );
  }

  if (
    healthProfileData["Height"]?.valueAsJSON?.value &&
    healthProfileData["Weight"]?.valueAsJSON?.value
  ) {
    return (
      <Typography variant="h4">
        {calculateBMI(
          Number(healthProfileData["Height"].valueAsJSON.value),
          Number(healthProfileData["Weight"].valueAsJSON.value)
        ).toFixed(2)}
      </Typography>
    );
  }

  return (
    <Typography
      color="neutral.500"
      sx={{
        pr: 1,
      }}
    >
      {t("HealthProfile.BodyMassIndex.placeholder")}
    </Typography>
  );
};

function getVerifiedStatus(lastUpdated: string | null) {
  if (lastUpdated && isOlderThanSixMonths(new Date(lastUpdated))) {
    return <IconLoader icon="CheckVerifiedFilledIcon" color="secondary" />;
  }
  return <IconLoader icon="CheckVerifiedFilledIcon" color="primary" />;
}

export default function BodyMassIndexHealthMeasurement({
  healthProfileData,
}: {
  healthProfileData: any;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useGlobalStore();

  const isHealthMeasurementDetailSupportedByUI =
    state.healthMeasurementsWithDetailView.filter(
      (clickThroughIdentifier: HealthMeasurementIdentifier) => {
        return (
          clickThroughIdentifier === HealthMeasurementIdentifier.BodyMassIndex
        );
      }
    ).length > 0;

  const hasDetailView =
    healthProfileData["BodyMassIndex"]?.valueAsJSON &&
    isHealthMeasurementDetailSupportedByUI;

  function navigateToDetailView() {
    if (hasDetailView) {
      navigate(
        `/home/health-measurement/${HealthMeasurementIdentifier.BodyMassIndex}/detail`
      );
    }
  }

  return (
    <>
      <Stack
        component="button"
        disabled={!hasDetailView}
        direction="row"
        alignItems="center"
        spacing={1}
        {...healthMeasurementListItemStyle}
        justifyContent="space-between"
        aria-label={"BMI"}
        onClick={navigateToDetailView}
      >
        <Stack direction="row">
          <Typography>{t("HealthProfile.BodyMassIndex.name")}</Typography>
          {healthProfileData["BodyMassIndex"]?.isVerified ? (
            getVerifiedStatus(healthProfileData["BodyMassIndex"]?.lastUpdated)
          ) : (
            <FaceIconBrand color="secondary" sx={{ p: 0.5 }} />
          )}
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <BodyMassIndexValue healthProfileData={healthProfileData} />
          {hasDetailView && (
            <IconLoader
              icon="ChevronNextIcon"
              color="neutral"
              sx={{ opacity: 1 }}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
}
