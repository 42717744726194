import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorBoundary } from "react-error-boundary";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import Panel from "../../../Common/components/Panel";
import {
  convertBooleanLikeStringToBoolean,
  isOlderThanSixMonths,
  convertStringToJSON,
  convertPxToRem,
} from "../../../../utils";
import { HealthMeasurementIdentifier, useGlobalStore } from "../../../../store";
import HealthScoreIndicator from "../HealthScoreIndicator";
import HealthProfileLegendModal from "../HealthProfileLegendModal";
import BodyMassIndexHealthMeasurement from "./components/BodyMassIndexHealthMeasurement";
import {
  healthMeasurementListItemStyle,
  utilityButtonStyle,
} from "../../../../theme";
import HealthMeasurementListElement from "./components/HealthMeasurementListElement";
import IconLoader from "../../../Common/components/IconLoader";
import { HealthProfileSuccessResponseData } from "../../../../services/core-api-adapter";
import BinahScanCallToActionPanel from "../../../Common/components/BinahScanCallToActionPanel";

function ErrorFallback() {
  const { t } = useTranslation();

  return (
    <Panel role="alert">
      <Typography variant="h2" align="center">
        {t("common.somethingWentWrong")}
      </Typography>
    </Panel>
  );
}

const expiredVerifiedStatusDisplayedForTypes = [
  "BodyMassIndex",
  "Height",
  "Weight",
  "MetabolicAge",
  "BodyFat",
  "BodyWaterPercent",
  "SystolicBloodPressure",
  "BloodType",
  "Pulse",
];

export function getVerifiedStatus(lastUpdated: string | null, type: string) {
  if (
    expiredVerifiedStatusDisplayedForTypes.includes(type) &&
    lastUpdated &&
    isOlderThanSixMonths(new Date(lastUpdated))
  ) {
    return <IconLoader icon="CheckVerifiedFilledIcon" color="secondary" />;
  }
  return <IconLoader icon="CheckVerifiedFilledIcon" color="primary" />;
}

interface HealthProfileProps {
  healthProfile: HealthProfileSuccessResponseData | null;
}

export default function HealthProfile({ healthProfile }: HealthProfileProps) {
  const { t } = useTranslation();
  const { state } = useGlobalStore();
  const [isLegendModalOpened, setIsLegendModalOpened] = useState(false);

  function onLegendModalClose() {
    setIsLegendModalOpened(false);
  }

  function onLegendModalOpen() {
    setIsLegendModalOpened(true);
  }

  const healthScore: number = Number(healthProfile?.healthScoreValue) || 0;

  const healthProfileData =
    healthProfile &&
    healthProfile.items.reduce((accumulator, item) => {
      accumulator[item.questionType] = item;
      accumulator[item.questionType].valueAsJSON = convertStringToJSON(
        item.data
      );
      return accumulator;
    }, {} as any);

  const smokingStatusValueAndUnit = (
    smokingStatus?: any,
    smokingFrequency?: any
  ): string | null => {
    const smokingStatusNormalised = String(
      smokingStatus?.valueAsJSON
    ).toLowerCase();

    if (smokingStatusNormalised === "no") {
      return t("HealthProfile.SmokingStatus.no");
    }

    if (
      smokingStatusNormalised === "yes" &&
      smokingFrequency?.valueAsJSON?.interval &&
      smokingFrequency?.valueAsJSON?.value
    ) {
      return t(
        `HealthProfile.SmokingFrequency.${smokingFrequency.valueAsJSON?.interval}` as any,
        {
          value: smokingFrequency.valueAsJSON?.value,
        }
      );
    }

    if (smokingStatusNormalised === "yes") {
      return t("HealthProfile.SmokingStatus.yes");
    }

    return null;
  };

  const getBloodTypeValue = (bloodTypeHealthMeasurementData: any) => {
    const bloodTypeValue =
      bloodTypeHealthMeasurementData?.valueAsJSON?.toUpperCase();

    if (bloodTypeValue === "UNKNOWN") {
      return t("HealthProfile.BloodType.unknown.label");
    }

    return bloodTypeValue;
  };

  const getBloodPressureValue = (
    systolicBloodPressure: any,
    diastolicBloodPressure: any
  ) => {
    if (
      systolicBloodPressure?.valueAsJSON?.value &&
      diastolicBloodPressure?.valueAsJSON?.value
    ) {
      return (
        systolicBloodPressure.valueAsJSON.value +
        "/" +
        diastolicBloodPressure.valueAsJSON?.value
      );
    }

    return null;
  };

  const booleanToNaturalLanguage = (value: boolean | null) => {
    if (value === true) {
      return t("common.yes");
    }
    if (value === false) {
      return t("common.no");
    }
    return null;
  };

  function getHealthMeasurementInternalState({
    data,
    valueOverride,
    unitOverride,
    isUserEditable,
    healthMeasurementIdentifier,
  }: {
    data: any;
    unitOverride: string | null | undefined;
    valueOverride: string | null | undefined;
    isUserEditable: boolean | undefined;
    healthMeasurementIdentifier: HealthMeasurementIdentifier;
  }) {
    const isHealthMeasurementEditSupportedByUI =
      state.healthMeasurementsWithEditView.filter((clickThroughIdentifier) => {
        return clickThroughIdentifier === healthMeasurementIdentifier;
      }).length > 0;

    const isHealthMeasurementDetailSupportedByUI =
      state.healthMeasurementsWithDetailView.filter(
        (clickThroughIdentifier) => {
          return clickThroughIdentifier === healthMeasurementIdentifier;
        }
      ).length > 0;

    const internalState: {
      value: string | null;
      unit: string | null;
      hasEditView: boolean | undefined;
      hasDetailView: boolean | undefined;
    } = {
      value: null,
      unit: null,
      hasEditView: data?.isUserEditable || isUserEditable,
      hasDetailView: isHealthMeasurementDetailSupportedByUI,
    };

    if (data || valueOverride) {
      internalState.value =
        data?.valueAsJSON?.value || data?.valueAsJSON || null;
      internalState.unit = data?.valueAsJSON?.unit || null;

      if (valueOverride) {
        internalState.value = valueOverride;
      }

      if (unitOverride || unitOverride === "") {
        internalState.unit = unitOverride;
      }

      if (
        internalState.hasEditView === true &&
        isHealthMeasurementEditSupportedByUI === true
      ) {
        internalState.hasEditView = true;
      } else {
        internalState.hasEditView = false;
      }
    }
    return internalState;
  }

  function healthMeasurementElementOrNull({
    healthMeasurementIdentifier,
    unitOverride,
    valueOverride,
    shouldDisplayEvenIfValueIsNull = true,
    healthRiskIndicator,
    isUserEditable = false,
    isVerified,
    lastUpdated,
  }: {
    healthMeasurementIdentifier: HealthMeasurementIdentifier;
    unitOverride?: string | null;
    valueOverride?: string | null;
    shouldDisplayEvenIfValueIsNull?: boolean;
    healthRiskIndicator?: string;
    isUserEditable?: boolean;
    isVerified?: boolean;
    lastUpdated?: string;
  }) {
    const data = healthProfileData[healthMeasurementIdentifier];

    const internalState = getHealthMeasurementInternalState({
      data,
      healthMeasurementIdentifier,
      isUserEditable,
      unitOverride,
      valueOverride,
    });

    const formattedInternalValue = internalState.unit
      ? `${internalState.value} ${internalState.unit}`
      : internalState.value;

    const component = (
      <HealthMeasurementListElement
        healthMeasurementIdentifier={healthMeasurementIdentifier}
        hasEditView={internalState.hasEditView}
        hasDetailView={internalState.hasDetailView}
        data={
          internalState.value ? formattedInternalValue : internalState.value
        }
        isVerified={isVerified || data?.isVerified}
        name={t(`HealthProfile.${healthMeasurementIdentifier}.name` as any)}
        healthRiskIndicator={healthRiskIndicator || data?.healthRiskIndicator}
        lastUpdated={lastUpdated || data?.lastUpdated}
      />
    );

    if (
      internalState.value !== null ||
      (internalState.value === null && shouldDisplayEvenIfValueIsNull)
    ) {
      return component;
    }

    return null;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {healthProfileData && (
        <Stack spacing={2}>
          <HealthProfileLegendModal
            isOpen={isLegendModalOpened}
            onClose={onLegendModalClose}
          />

          <Panel overflow="unset">
            <HealthScoreIndicator healthScore={healthScore} />
          </Panel>

          <BinahScanCallToActionPanel />

          <Panel>
            <Stack spacing={1} divider={<Divider />}>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Box py={1}>
                  <Typography variant="h5" alignItems="center">
                    {t("HealthProfile.legend.verifiedData")}
                  </Typography>
                  <Typography variant="subtitle2">
                    {healthProfile?.lastUpdatedAt?.split("T")[0]}
                  </Typography>
                </Box>
                <Button
                  size="small"
                  color="neutral"
                  variant="outlined"
                  onClick={onLegendModalOpen}
                  startIcon={
                    <IconLoader
                      icon="InformationSymbolInCircleIcon"
                      sx={{ fontSize: convertPxToRem(12) }}
                    />
                  }
                  sx={utilityButtonStyle}
                >
                  <Typography variant="body2" fontWeight={600}>
                    {t("HealthProfile.legend.info")}
                  </Typography>
                </Button>
              </Stack>
              <Stack
                component="ul"
                aria-label={t("HealthProfile.HealthMeasurementList.title")}
                spacing={1}
                divider={<Divider />}
              >
                {healthMeasurementElementOrNull({
                  healthMeasurementIdentifier:
                    HealthMeasurementIdentifier.SexAtBirth,
                })}

                {healthMeasurementElementOrNull({
                  healthMeasurementIdentifier:
                    HealthMeasurementIdentifier.DateOfBirth,
                })}

                {healthMeasurementElementOrNull({
                  healthMeasurementIdentifier: HealthMeasurementIdentifier.Age,
                  shouldDisplayEvenIfValueIsNull: false,
                })}

                {healthMeasurementElementOrNull({
                  healthMeasurementIdentifier:
                    HealthMeasurementIdentifier.MetabolicAge,
                  shouldDisplayEvenIfValueIsNull: false,
                })}

                <Stack>
                  <Stack {...healthMeasurementListItemStyle}>
                    <BodyMassIndexHealthMeasurement
                      healthProfileData={healthProfileData}
                    />
                  </Stack>
                  <Stack pl={1} pt={1} spacing={1}>
                    <Typography variant="body2">
                      {t("HealthProfile.BodyMassIndex.description")}
                    </Typography>

                    {healthMeasurementElementOrNull({
                      healthMeasurementIdentifier:
                        HealthMeasurementIdentifier.Weight,
                      unitOverride: "kg",
                    })}

                    {healthMeasurementElementOrNull({
                      healthMeasurementIdentifier:
                        HealthMeasurementIdentifier.Height,
                      unitOverride: "cm",
                    })}
                  </Stack>
                </Stack>

                {healthMeasurementElementOrNull({
                  healthMeasurementIdentifier:
                    HealthMeasurementIdentifier.BodyFat,
                  unitOverride: "",
                  shouldDisplayEvenIfValueIsNull: false,
                })}

                {healthMeasurementElementOrNull({
                  healthMeasurementIdentifier:
                    HealthMeasurementIdentifier.BodyWaterPercent,
                  unitOverride: "",
                  shouldDisplayEvenIfValueIsNull: false,
                })}

                {healthMeasurementElementOrNull({
                  healthMeasurementIdentifier:
                    HealthMeasurementIdentifier.BloodPressure,
                  valueOverride: getBloodPressureValue(
                    healthProfileData["SystolicBloodPressure"],
                    healthProfileData["DiastolicBloodPressure"]
                  ),
                  isVerified:
                    healthProfileData["SystolicBloodPressure"]?.isVerified ||
                    healthProfileData["DiastolicBloodPressure"]?.isVerified,
                  lastUpdated:
                    healthProfileData["SystolicBloodPressure"]?.lastUpdated ||
                    healthProfileData["DiastolicBloodPressure"]?.isVerified,
                  healthRiskIndicator:
                    healthProfileData["SystolicBloodPressure"]
                      ?.healthRiskIndicator ||
                    healthProfileData["DiastolicBloodPressure"]
                      ?.healthRiskIndicator,
                  isUserEditable: false,
                  shouldDisplayEvenIfValueIsNull: false,
                })}

                {healthMeasurementElementOrNull({
                  healthMeasurementIdentifier:
                    HealthMeasurementIdentifier.BloodType,
                  valueOverride: getBloodTypeValue(
                    healthProfileData["BloodType"]
                  ),
                })}

                {healthMeasurementElementOrNull({
                  healthMeasurementIdentifier:
                    HealthMeasurementIdentifier.Pulse,
                  unitOverride: "",
                  shouldDisplayEvenIfValueIsNull: false,
                })}

                {healthMeasurementElementOrNull({
                  healthMeasurementIdentifier:
                    HealthMeasurementIdentifier.SaturationOfPeripheralOxygen,
                  unitOverride: "",
                  shouldDisplayEvenIfValueIsNull: false,
                })}

                {healthMeasurementElementOrNull({
                  healthMeasurementIdentifier:
                    HealthMeasurementIdentifier.BreathingRate,
                  shouldDisplayEvenIfValueIsNull: false,
                })}

                {healthMeasurementElementOrNull({
                  healthMeasurementIdentifier:
                    HealthMeasurementIdentifier.SmokingStatusAndFrequency,
                  unitOverride: "",
                  valueOverride: smokingStatusValueAndUnit(
                    healthProfileData["SmokingStatus"],
                    healthProfileData["SmokingFrequency"]
                  ),
                  healthRiskIndicator:
                    healthProfileData["SmokingStatus"]?.healthRiskIndicator,
                  isUserEditable:
                    healthProfileData["SmokingStatus"]?.isUserEditable,
                })}

                {healthMeasurementElementOrNull({
                  healthMeasurementIdentifier:
                    HealthMeasurementIdentifier.ConditionsStatusAndList,
                  unitOverride: "",
                  valueOverride: booleanToNaturalLanguage(
                    convertBooleanLikeStringToBoolean(
                      healthProfileData["ConditionsStatus"]?.valueAsJSON
                    )
                  ),
                  healthRiskIndicator:
                    healthProfileData["ConditionsStatus"]?.healthRiskIndicator,
                  isUserEditable:
                    healthProfileData["ConditionsStatus"]?.isUserEditable,
                })}

                {healthMeasurementElementOrNull({
                  healthMeasurementIdentifier:
                    HealthMeasurementIdentifier.AllergiesStatusAndList,
                  unitOverride: "",
                  valueOverride: booleanToNaturalLanguage(
                    convertBooleanLikeStringToBoolean(
                      healthProfileData["AllergiesStatus"]?.valueAsJSON
                    )
                  ),
                  healthRiskIndicator:
                    healthProfileData["AllergiesStatus"]?.healthRiskIndicator,
                  isUserEditable:
                    healthProfileData["AllergiesStatus"]?.isUserEditable,
                })}
              </Stack>
            </Stack>
          </Panel>
        </Stack>
      )}
    </ErrorBoundary>
  );
}
