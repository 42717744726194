import { Box } from "@mui/material";
import theme from "../../../../../../theme";

const healthRiskIndicatorColorMap: any = {
  BAD: theme.palette.error.main,
  GOOD: theme.palette.accent1.main,
  OK: theme.palette.secondary[700],
};

export default function HealthItemRiskIndicator({
  healthRiskIndicator,
}: {
  healthRiskIndicator: string | undefined;
}) {
  if (!healthRiskIndicator) {
    return null;
  }

  return healthRiskIndicator in healthRiskIndicatorColorMap ? (
    <Box
      sx={{
        background: healthRiskIndicatorColorMap[healthRiskIndicator],
        borderRadius: "50%",
        height: 10,
        ml: 1,
        minWidth: 10,
      }}
    />
  ) : null;
}
