import { Stack, Typography } from "@mui/material";
import Panel from "@/features/Common/components/Panel";
import IconLoader from "../IconLoader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { trackEvent } from "@/services/analytics-adapter";
import useGetMemberships from "@/features/Common/hooks/useGetMemberships";
import ButtonWithAnalytics from "@/features/Common/components/ButtonWithAnalytics";

export default function BinahScanCallToActionPanel() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { firstMembership } = useGetMemberships();

  function startFaceScan() {
    trackEvent({
      event: "action.healthCheckManuallyInitiated",
      source: "my health profile",
    });
    navigate("/binah-scan?skipScanPrompt=true");
  }

  function shouldShowFaceScanCallToAction() {
    return (
      firstMembership.productDetails?.supportedActions?.initiateBinahScan ===
      true
    );
  }

  return shouldShowFaceScanCallToAction() ? (
    <Panel>
      <Stack spacing={3} py={1}>
        <Stack spacing={1}>
          <Typography variant="h4">
            {t("BinahScanCallToActionPanel.title")}
          </Typography>
          <Typography variant="body2">
            {t("BinahScanCallToActionPanel.subtitle")}
          </Typography>
        </Stack>
        <ButtonWithAnalytics
          page="HealthProfile"
          text={t("BinahScanCallToActionPanel.buttonLabel")}
          intent="navigational"
          onClick={startFaceScan}
          startIcon={<IconLoader icon="SmilingFaceInRoundedSquareIcon" />}
        >
          {t("BinahScanCallToActionPanel.buttonLabel")}
        </ButtonWithAnalytics>
      </Stack>
    </Panel>
  ) : null;
}
