import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { getVerifiedStatus } from "../..";
import {
  trackEvent,
  trackUserInteraction,
} from "../../../../../../services/analytics-adapter";
import { healthMeasurementListItemStyle } from "../../../../../../theme";
import FaceIconBrand from "../../../../../../theme/icons/FaceIconBrand";
import {
  convertPascalCaseToWords,
  convertPxToRem,
} from "../../../../../../utils";
import HealthItemRiskIndicator from "../HealthRiskIndicator";
import IconLoader from "../../../../../Common/components/IconLoader";

interface HealthMeasurementListElementProps {
  data: string | null | undefined;
  isVerified: boolean;
  name: string;
  healthMeasurementIdentifier: string;
  healthRiskIndicator: string;
  lastUpdated: string | null;
  hasEditView?: boolean;
  hasDetailView?: boolean;
}

export default function HealthMeasurementListElement({
  data,
  isVerified,
  hasEditView = true,
  hasDetailView = false,
  name,
  healthMeasurementIdentifier,
  healthRiskIndicator,
  lastUpdated,
}: HealthMeasurementListElementProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function onClick() {
    if (hasDetailView && data) {
      navigate(
        `/home/health-measurement/${healthMeasurementIdentifier}/detail`
      );
    } else if (hasEditView) {
      navigate(`/home/health-measurement/${healthMeasurementIdentifier}`);
    }
    trackUserInteraction({
      linkText: `Health profile | ${convertPascalCaseToWords(healthMeasurementIdentifier)}`,
      linkIntent: "navigational",
      linkScope: "button",
    });
    trackEvent({
      event: "action.healthProfileMeasurementViewed",
      healthMeasurementIdentifier: convertPascalCaseToWords(
        healthMeasurementIdentifier
      ),
    });
  }

  function getHealthItemValuePlaceholder() {
    return hasEditView ? (
      <Stack direction="row" alignItems="center">
        <IconLoader
          icon="ActiveNotificationIcon"
          color="primary"
          sx={{ fontSize: convertPxToRem(14) }}
        />
        <Typography color="primary" variant="h4" ml={1}>
          {t("common.add")}
        </Typography>
      </Stack>
    ) : (
      <Typography variant="h4">{t("common.noData")}</Typography>
    );
  }

  return (
    <Stack
      component="button"
      disabled={!(hasDetailView || hasEditView)}
      aria-label={name}
      {...healthMeasurementListItemStyle}
      onClick={onClick}
    >
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography textAlign="left">{name}</Typography>
        {isVerified ? (
          getVerifiedStatus(lastUpdated, healthMeasurementIdentifier)
        ) : (
          <FaceIconBrand color="secondary" sx={{ p: 0.5 }} />
        )}
      </Stack>

      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {data ? (
          <Stack direction={"row"} alignItems="center">
            <Typography variant="h4">{data}</Typography>
            <HealthItemRiskIndicator
              healthRiskIndicator={healthRiskIndicator}
            />
          </Stack>
        ) : (
          getHealthItemValuePlaceholder()
        )}

        <Stack alignItems="center" justifyContent="center" sx={{ height: 52 }}>
          {(hasEditView || hasDetailView) && (
            <IconLoader
              icon="ChevronNextIcon"
              color="neutral"
              sx={{ opacity: 1 }}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
